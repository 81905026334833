import React, { useState } from 'react';
import './App.css';
import NavBar from './jsx_view/navBar';
import Footer from './jsx_view/footer';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './pages/Login';
import Signup from './pages/sign_up';
import Home from './pages/Home';
import Maps from './map/maps';
import MapsPage from './pages/MapsPage';
import { getUser, setUser, removeUser } from "./data/repository";
// import { getUser, removeUser, addUser} from "./data/repository";


export function App() {
  // addUser() // initialize hardcoded login credentials (loads function in repository.js)
  const [username, setUsername] = useState(getUser().username); // remove getUser() to make logins not persistent across website visits?
  const [email, setUserEmail] = useState(getUser().email);

  const loginUser = (username, email) => {
    console.log("app.js username is: ")
    console.log(username);
    setUsername(username);
    setUserEmail(email);
    setUser(username, email); // Calls setUser function in repository.js. Might need for format as {username: username}?
  }

  const logoutUser = () => {
    removeUser();
    setUsername(null);
    setUserEmail(null);
  }

  return ( // Contains items to display. Within the header class, we currently load the Bootstrap form
  <BrowserRouter>
    <div className="App"> 
    <header className="App-header">
      
        
      </header>
      <NavBar username={username} logoutUser={logoutUser}/>
      <header className="App-body">
        <div>
        
          <Routes>
              <Route path="" element={<Home />} />
              <Route path="Home" element={<Home />} />
              <Route path="Login" element={<Login loginUser={loginUser} logoutUser={logoutUser} />} />
              <Route path="Signup" element={<Signup loginUser={loginUser}/>} />
              <Route path="Maps" element={<Maps />} />
              <Route path="MapsPage" element={<MapsPage />} />
          </Routes>
          
        </div>
      </header> 
    <footer>
      <Footer/>
    </footer>
    
    </div>
  </BrowserRouter>
  );
}

export default App;
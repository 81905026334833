import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import map1 from "../images/map thumbnails/map 1.png";
import map2 from "../images/map thumbnails/map 2.png";


export function MapsPage(props) {
    

    return (
        <>
        <div class="pageSpan">
          <div class="saved-maps-container">
            <h1 className='mapsPage-title'>Saved Maps:</h1>
            <div class="grid-container">
              <div class="grid-item">
                <img className='map-thumbnails' src={map1} alt="map image 1"/>
                <h4>Custom map 1</h4>
              </div>
              <div class="grid-item">
                <img className='map-thumbnails' src={map2} alt="map image 2"/>
                <h4>Custom map 2</h4>
              </div>
              <div class="grid-item">
                <img className='map-thumbnails' src={map1} alt="map image 2"/>
                <h4>Custom map 3</h4>
              </div>
              <div class="grid-item">
                <img className='map-thumbnails' src={map2} alt="map image 1"/>
                <h4>Custom map 4</h4>
              </div>
              <div class="grid-item">
                <img className='map-thumbnails' src={map1} alt="map image 2"/>
                <h4>Custom map 5</h4>
              </div>
              <div class="grid-item">
                <img className='map-thumbnails' src={map2} alt="map image 2"/>
                <h4>Custom map 6</h4>
              </div>
            </div>
          </div>
        </div>
        </>
      )
}
export default MapsPage;
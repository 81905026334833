import axios from "axios";

// --- Constants ----------------------------------------------------------------------------------
const API_HOST = "http://localhost:4000";
const CURR_USER_KEY = "user";
const CURR_EMAIL_KEY = "email";

// --- User ---------------------------------------------------------------------------------------
async function verifyUser(email, password) {
  const response = await axios.get(API_HOST + "/api/users/login", { params: { email, password } });
  console.log("email is");
  console.log(email);

  console.log("[password] is");
  console.log(password);

  return response.data;
}

async function findUser(id) {
  const response = await axios.get(API_HOST + `/api/users/select/${id}`);

  return response.data;
}

async function createUser(user) {
  const response = await axios.post(API_HOST + "/api/users", user);

  return response.data;
}

async function deleteUser(id) {
  console.log(id)
  const response = await axios.delete(API_HOST + `/api/users/delete/${id}`); //Delete function removes user from database 

  return response.data;
}

async function updatedUser(user) {
  const response = await axios.post(API_HOST + '/api/users/update', user);
  return response.data;

}

async function emailExists(email) { // Checks if email already exists in database. Returns just email, null if not found
  const response = await axios.get(API_HOST + `/api/users/select/email/${email}`);
  console.log(response);
  return response.data;
}

// --- Post ---------------------------------------------------------------------------------------
async function getPosts() {
  const posts = await axios.get(API_HOST + "/api/posts");
  var user_posts = posts.data;

  var userPostsDB = [];
  if (user_posts == null) {
      return null;
  } else {
      for(const post of user_posts) {
          {
              userPostsDB.push({
                  postId: post.post_id,
                  parentId: post.parent_id,
                  postUser: post.user.first_name,
                  text: post.text,
                  date: post.createdAt
              })
          }
      }
      return userPostsDB;
  }
}

async function createPost(post) {
  const response = await axios.post(API_HOST + "/api/posts", post);

  return response.data;
}

async function deleteUserPosts(id) { // Deletes all posts by a specific user
  const response = await axios.delete(API_HOST + `/api/posts/deleteUserPosts/${id}`); //Delete user posts function needs to be called to remove user 

  return response.data;
}

async function deletePost(id) { // Deletes a specific post
  const response = await axios.delete(API_HOST + `/api/posts/deletePost/${id}`); 

  return response.data;
}

// --- Post Replies -------------------------------------------------------------------------------
async function getReplies(id) {
  const posts = await axios.get(API_HOST + `/api/posts/postreplies/${id}`);
  var post_replies = posts.data;

  var userPostsDB = [];
  if (post_replies == null) {
      return null;
  } else {
      for(const post of post_replies) {
          {
              userPostsDB.push({
                  postId: post.post_id,
                  parentId: post.parent_id,
                  postUser: post.user.first_name,
                  text: post.text,
                  date: post.createdAt
              })
          }
      }
      return userPostsDB;
  }
}

async function deletePostReplies(id) { // Deletes all replies by a specific user
  const response = await axios.delete(API_HOST + `/api/posts/deletePostReplies/${id}`); //Delete all replies to a certain post

  return response.data;
}

// ------Post edit--------------------------------------------------------------------------------
async function createEditedPost(post) {
  const response = await axios.post(API_HOST + "/api/posts/createEdit", post);

  return response.data;
}

// ------Reactions--------------------------------------------------------------------------------
async function getReaction(id) { // Get reactions for certain post
  const reaction = await axios.get(API_HOST + `/api/reaction/deletePostReplies/${id}`);
  var user_posts = reaction.data;

  var userPostsDB = [];
  if (user_posts == null) {
      return null;
  } else {
      for(const post of user_posts) {
          {
              userPostsDB.push({
                  parentId: reaction.parent_id,
                  text: reaction.text,
              })
          }
      }
      return userPostsDB;
  }
}

async function createReaction(reaction) { // Create a new reaction
  const response = await axios.post(API_HOST + "/api/reaction", reaction);

  return response.data;
}
// --- Helper functions to interact with local storage --------------------------------------------
function setUser(firstName, email) { // Sets the currently logged in user
  localStorage.setItem(CURR_USER_KEY, firstName);
  localStorage.setItem(CURR_EMAIL_KEY, email);
}


function getUser() { // Gets the currently logged in user
  return {username: localStorage.getItem(CURR_USER_KEY), email: localStorage.getItem(CURR_EMAIL_KEY)};
}

function removeUser() {
  localStorage.removeItem(CURR_USER_KEY);
  localStorage.removeItem(CURR_EMAIL_KEY);
}

export {
  verifyUser, findUser, createUser, deleteUser, emailExists, updatedUser,
  getPosts, createPost, deleteUserPosts, deletePost, getReplies, deletePostReplies, createEditedPost,
  getReaction, createReaction,
  setUser, getUser, removeUser
}
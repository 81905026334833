import L from "leaflet";
import { createControlComponent } from "@react-leaflet/core";
import "leaflet-routing-machine";

//NOTE: Can use [waypointMode: "snap",] as a property in the routing.control instance to essentially make it snap to roads.
// Probs will be quite useful for snapped polygon drawing mode!


const createRoutingMachineLayer = (props) => {
  const instance = L.Routing.control({ // BUG: If you switch away from router before route is loaded, will throw null error
    waypoints: [
      L.latLng(-37.81929583083403, 144.95459634331667),
      L.latLng(-37.83482777096238, 145.0678466087801),
    ],
    lineOptions: {
      styles: [{ color: "#484bfa", weight: 4 }, {color: "#484bfa", opacity: 0.5, weight: 3}, {color: "#484bfa", opacity: 0.5, weight: 3}]
    },
    show: true,
    addWaypoints: true,
    routeWhileDragging: true,
    draggableWaypoints: true,
    fitSelectedRoutes: true,
    showAlternatives: false,
    fitSelectedRoutes: false
  });

  return instance;
};


const RoutingMachine = createControlComponent(createRoutingMachineLayer);

export default RoutingMachine;
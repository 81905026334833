import { Link } from "react-router-dom"

function NavBar(props) {


    

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <img className="navbar-brand"src={require("../images/sharemaps logo.png")} alt="Homepage" width="120" height=""></img>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            
            {props.username != null ? ( //conditional statement to change what is displayed in navBar depending on if user logged in or not
                <>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup"> {/* Class for left aligned navbar items */}
                        <div className="navbar-nav">
                        <Link to="/Home" className="nav-item nav-link active" href="#">Home <span className="sr-only"></span></Link>
                        <Link to="/Maps" className="nav-item nav-link" href="#">Maps</Link>
                        </div>
                    </div>

                    <div className="navbar-collapse collapse justify-content-end" id="navbarNav"> {/* Class for right aligned navbar items */}
                        <div className="navbar-nav">
                        <Link to="/Account" id="login" className="nav-item nav-link" href="#">My Account</Link>
                        <Link to="/Login" id="login" className="nav-item nav-link" href="#" onClick={props.logoutUser}>Logout</Link> {/* add: Onclick set isLoggedIn prop = false */}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup"> {/* Class for left aligned navbar items */}
                        <div className="navbar-nav">
                        <Link to="/Home" className="nav-item nav-link active" href="#">Home <span className="sr-only"></span></Link>
                        <Link to="/Maps" className="nav-item nav-link" href="#">Maps</Link>                                             {/* This is temp for testing, remove later so maps is only viewable once logged in */}
                        <Link to="/MapsPage" className="nav-item nav-link" href="#">MapsPage</Link>      
                        </div>
                    </div>

                    <div className="navbar-collapse collapse justify-content-end" id="navbarNav"> {/* Class for right aligned navbar items */}
                        <div className="navbar-nav">
                        <Link to="/Signup" id="login" className="nav-item nav-link" href="#">Sign Up</Link>
                        <Link to="/Login" id="login" className="nav-item nav-link" href="#">Login</Link>
                        </div>
                    </div>
                </>
            )}

        </nav>

    );
  }
  
  export default NavBar;
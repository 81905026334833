import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';


export function Home(props) {

    return (
        <>
        
        <div class="pageSpan">

          <Card className="home-text">
            <Card.Header><h3>Welcome to Sharemaps</h3></Card.Header>
            <Card.Body>
              <Card.Text>
                At this stage, this is just an early prototype for a maps website I am building as a hobby project. The aim is to facilitate the crowdsourcing of useful
                map information (eg parking zones in cities, free bush camping zones in national parks and much more) by providing a service where anyone can make a publicly editable map.
                <br></br><br></br>These maps will be shareable via link, or found using the search functionality on the site.

                If you have any suggestions, feedback, or just want more info, feel free to connect on LinkedIn.
              </Card.Text>
              <Button variant="primary" href="https://www.linkedin.com/in/dario-killenberger/">LinkedIn</Button>
            </Card.Body>
          </Card>
        
        </div>
        </>
      )
}
export default Home;
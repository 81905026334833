import React, { useState, useEffect } from 'react';
import L from 'leaflet';
import { MapContainer, TileLayer, useMap, FeatureGroup, Circle, Marker } from 'react-leaflet'
import { EditControl } from "react-leaflet-draw"
import RoutingMachine from './RoutingMachine';

import { useLeafletContext, leafletElement, createLayerComponent } from '@react-leaflet/core'
import Leaflet from "leaflet";
import "leaflet-routing-machine";
import "leaflet/dist/leaflet.css";

import EsriLeafletGeoSearch from "react-esri-leaflet/plugins/EsriLeafletGeoSearch";

// ------------ getting marker images to show -------------------- //
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});
L.Marker.prototype.options.icon = DefaultIcon;
// --------------------------- END -------------------------------- //

export function Maps(props) {
    return (  
        <>
        {/* <div class="sidebar">
            <div class="sidebar_components">
                <img src={require('../images/creative.png')} style={{ width: '50px'}}/>
            </div>
        </div> */}

        <MapContainer center={[-37.813335994571474, 144.96300740852396]} zoom={9} Component >
            <TileLayer 
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            <div>
                <FeatureGroup>
                    <EditControl
                        position="bottomright"
                        draw={{
                        circle: false,
                        marker: true,
                        polyline: false,
                        circlemarker: false
                        }}
                    />
                </FeatureGroup>
            </div>
            <RoutingMachine/>
            <EsriLeafletGeoSearch 
                providers={{
                    arcgisOnlineProvider: {
                    token: "AAPK4939369ab626462da80583ebe654eaacSQ7VWr1OI31erbZne7gvC1GzpyDGcoawDdyad9mpCpEgpn4n7nPAQ6U9w2cIwXEf",
                    label: "ArcGIS Online Results",
                    maxResults: 10,
                    placeholder: 'Search for an address',
                    title: 'Address Search',
                    searchBounds: 2000,
                    zoomToResult: true
                    },
                }}
            />;

        </MapContainer>
        </>
    )
}
export default Maps;
import React, { useState } from "react";

function Footer() {
    return (
      <div class="card">
        <div class="card-body">
          Sharemaps (c) 2023 by Dario Killenberger
        </div>
      </div>
    );
  }
  
  export default Footer;
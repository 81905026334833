import React, { useState }from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import { emailExists, createUser, getUser, findUser} from '../data/repository';
// import { addUser, emailExists, getUser } from '../data/repository';

export function Signup(props){
  const [alert2, setAlert] = useState("null");

  const onSubmit = async (e) => { 
    //this section should run when form is submitted. Uses onSubmit so that it doesn't run if mandatory fields aren't filled
    e.preventDefault(); //prevents form from refreshing when it is submitted

    // Gets all the values from the form fields
    var firstName = document.getElementById('formGridFirstName').value;
    var lastName = document.getElementById('formGridLastName').value;
    var email = document.getElementById('formGridEmail').value;
    var password = document.getElementById('formGridPassword').value;
    var repeatPassword = document.getElementById('formGridRepeatPassword').value;

    // ------------------------ NEW ------------------------ \\
    var emailExis = await emailExists(email); // Checks if email exists, using sequelize. Note 'await' is necessary, to will simply return pending. Async on line also needed
    // console.log("email exists:");
    // console.log(emailExis);

    if (emailExis !== null) { //check for existing account with that email, if emailExist.email is not null, that means the email was found
      setAlert("emailDuplicate")
    } else {
      if (password === repeatPassword) { // Makes sure both password fields match
        if (password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,25}$/)) { //Make sure password contains at least one uppercase char, one lowercase char, one number; and is at least 8 characters
          createUser({ first_name: firstName, last_name: lastName, email: email, password: password }) //Adds new user

          props.loginUser(firstName, email) //logs user in using the setUsername useState, also sets localstorage curr_user

      // ------------------------ END NEW ------------------------ \\
          setAlert(true) //Sets alert to pass
            setTimeout(() => {
              setAlert("null")
              navigate("../Account", { replace: true });
            }, 1000);
        } else {
          setAlert("passQualityFail") //Sets alert to Password Requirements Not Met
        }
      } else {
        setAlert(false) //Sets alert to Passwords Not Matching
      }
    }
  };



  let navigate = useNavigate();
  return (
    <>
    <div class="pageSpan">
      {alert2 === true && ( //display signup successful alert
          <Alert key='primary' variant='primary'>
              Signup Successful!
          </Alert>
      )}

      {alert2 === "passQualityFail" && ( //display Password Requirements Not Met alert
          <h3><Alert key='danger' variant='danger'>
          Password Requirements Not Met!
          </Alert></h3>
      )}

      {alert2 === false && ( //display Passwords Not Matching alert
          <h3><Alert key='danger' variant='danger'>
              Passwords Not Matching!
          </Alert></h3>
      )}

      {alert2 === "emailDuplicate" && ( //display Email already bound to an account alert
          <h6><Alert key='danger' variant='danger'>
              Email already bound to an account. Please log in instead!
          </Alert></h6>
      )}
      
      <Card bg="light" border="primary" text="dark" style={{ width: '30rem' }}>
          <Card.Header>Sign Up</Card.Header>
          <Card.Body>
            <Form onSubmit={onSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridFirstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control type="name" placeholder="Enter First Name" required/>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridLastName">
                  <Form.Label>Surname</Form.Label>
                  <Form.Control placeholder="Enter Surname" required/>
                </Form.Group>
              </Row>

              <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" placeholder="Enter email" required/>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGridPassword">
                <Form.Label>Enter Password</Form.Label>
                <Form.Control alt="password1" type="password" placeholder="Password" required/>
                <Form.Label id="passRequirementsLabel">*Password Requirements: 8 to 25 characters long, at least one Uppercase, one lowercase and one numeric character</Form.Label>
              </Form.Group>
              

              <Form.Group className="mb-3" controlId="formGridRepeatPassword">
                <Form.Label>Repeat Password</Form.Label>
                <Form.Control alt="password2" type="password" placeholder="Password" required/>
              </Form.Group>


              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Card.Body>
        </Card>
    </div>
    </>
  )
}

export default Signup;